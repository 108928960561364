import axios from "../../lib/axios";

export async function getAll() {
    const response = await axios.get('/obituaries');
    return response.data;
}

export async function get(slug) {
    const response = await axios.get('/obituaries/' + slug);
    return response.data;
}

export async function create(data) {
    // await axiosClient.get('/sanctum/csrf-cookie').then(csrf => {
        const response = await axios.post('/obituaries', data);
        return response.data;
    // });
}

export async function update(slug, type) {
    let data = {_method: 'PUT'};
    const response = await axios.post('/obituaries/' + slug + '/' + type, data);
    return response.data;
}
