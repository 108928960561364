import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import React from "react";

export default function CallToAction() {

    return (
        <div className={`cta-section w-100 float-left text-center`}>
            <Container>
                <div className={`cta-title w-100 float-left text-center`}>Opret en dødsannonce,<br /> nemt og enkelt.</div>
                <p>SidsteHilsener.dk forenkler processen med at indrykke din annonce i både Danmarks førende aviser som Børsen, Berlingske & Politikken, samt i lokalaviser over hele landet. Spar tid og besvær med at kontakte hver avis individuelt; gennem vores platform kan du enkelt og hurtigt få din annonce publiceret med et enkelt klik.</p>
                <div className={`cta-btn w-100 float-left horizontal-center`}>
                    <Link to={"/opret-en-dodsannoncen"} className="w-100 float-left">Opret en dødsannonce</Link>
                </div>
            </Container>
        </div>
    )
}
