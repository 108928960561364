import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading";
import styles from '../../styles/ad-view.module.scss';
import { get as getObituary } from "../../services/Apis/Obituaries.service";
import { format } from 'date-fns';
import { useParams } from "react-router-dom";
import IObituary from "../../interfaces/IObituary";
import mapImage from "../../assets/images/map.jpg";
import Faq from "../../components/Faq";
import CallToAction from "../../components/CallToAction";
import { Dropdown } from "react-bootstrap";
import { update as doLikeOrReport } from "../../services/Apis/Obituaries.service";
import toast, { Toaster } from 'react-hot-toast';
import {Link} from "react-router-dom";

function Obituary() {

    const [isLoading, setIsLoading] = useState(true);
    const [obituary, setObituary] = useState<IObituary>();
    const [obituaryUrl, setObituaryUrl] = useState('');

    const {slug} = useParams();

    const toastStyles = {
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            fontSize: '16px'
        },
    };

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);

        toast.success('Linket er kopieret.', toastStyles);
    }

    const handleAction = async (type: string) => {
        const response = await doLikeOrReport(slug, type);

        if (type === 'report') {
            toast.success('Tak for din opmærksomhed. Vi undersøger annoncen', toastStyles);
        } else {
            toast.success('Tak skal du have.', toastStyles);
            setObituary(response);
        }
    }

    useEffect(() => {
        (async () => {
            const response = await getObituary(slug);
            setObituary(response);
            setIsLoading(false);
            setObituaryUrl(process.env.REACT_APP_URL + '/dodsannoncen/' + slug);
        })();
    }, [])

  return (
      <Layout>
          <Toaster position="top-right" />
          <>
              {isLoading &&
                  <Loading/>
              }

              {!isLoading &&
                  obituary !== undefined &&
                      <>
                          <div className="fancy-checkbox-row-wrp w-100 float-left">
                              <div className="container">
                                  <div className="fancy-checkbox-row">

                                      <Dropdown className={"float-left"}>
                                          <Dropdown.Toggle id="dropdown-share" className={"btn share"}>
                                              <div className={"circle"}></div>
                                              <div className={"text"}>Del</div>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu as={"ul"}>
                                              <span className="dropdown-menu-arrow"></span>
                                              <Dropdown.Item as={"li"}><a role={"button"} onClick={() => { handleCopy(obituaryUrl) }}>Kopiere link</a></Dropdown.Item>
                                              <Dropdown.Divider />
                                              <Dropdown.Item as={"li"}><Link target={"_blank"} to={"https://twitter.com/intent/tweet?text=" + obituaryUrl}>Del på Twitter</Link></Dropdown.Item>
                                              <Dropdown.Item as={"li"}><Link target={"_blank"} to={"https://www.facebook.com/sharer/sharer.php?u=" + obituaryUrl}>Del på Facebook</Link></Dropdown.Item>
                                              <Dropdown.Item  as={"li"}><Link target={"_blank"} to={"https://www.linkedin.com/shareArticle?mini=true&url=" + obituaryUrl}>Del på LinkedIn</Link></Dropdown.Item>
                                          </Dropdown.Menu>
                                      </Dropdown>

                                      <button className={"btn love"} onClick={(e) => handleAction('love')}>
                                          <div className={"circle"}></div>
                                          <div className={"text"}>{ obituary.likes }</div>
                                      </button>
                                      <button className={"btn report"} onClick={(e) => handleAction('report')}>
                                          <div className={"circle"}></div>
                                          <div className={"text"}>Anmeld</div>
                                      </button>
                                  </div>
                              </div>
                          </div>

                          <div className={`${styles['adview-top']} w-100 float-left`}>
                              <Container>
                                  <Row className={'gx-lg-5'}>
                                      <Col lg={6}>
                                          <div className={`${styles['ad-view-left-info']} w-100 float-left text-center`}>
                                              <ul>
                                                  <li>❤</li>
                                                  <li><span className={styles['large-text']}>{ obituary.full_name }</span><br />
                                                      { obituary.message_line_2 }<br />
                                                      <span className={styles['large-text']}>{ obituary.birth_date != null ? format(new Date(obituary.birth_date), 'dd.MM.yyyy')+ ' - ' : '' }{format(new Date(obituary.departed_date), 'dd.MM.yyyy')}</span></li>
                                                  <li> { obituary.message_line_3 }<br />
                                                      <strong>{ obituary.family_names }</strong></li>
                                                  <li>{ obituary.type == 'funeral' ? 'Bisættelse' : 'Begravelse' } finder sted i: <u>{ obituary.address } -</u><br />
                                                      <u>{format(new Date(obituary.funeral_date), 'dd.MM.yyyy')} - Kl. { obituary.funeral_time }</u></li>
                                                  <li>{ obituary.additional_information }</li>
                                              </ul>
                                          </div>
                                      </Col>
                                      <Col lg={6} className={'step-right'}>
                                          <div className={`${styles['funeral-information']} w-100 float-left`}>
                                              <div className={`${styles['funeral-information-title']} w-100 float-left`}>Praktiske informationer</div>
                                              <div className={`${styles['funeral-info-data']} w-100 float-left`}>
                                                  <p>{ obituary.type == 'funeral' ? 'Bisættelse' : 'Begravelse' } finder sted i: { obituary.address }, { obituary.city } - {format(new Date(obituary.funeral_date), 'dd.MM.yyyy')} - Kl. { obituary.funeral_time }</p>
                                                  <p>{ obituary.additional_information }</p>
                                                  <div className={`${styles['info-map']} w-100 float-left`}><a href="#"><img
                                                      src={mapImage} alt="" className="img-fluid d-block m-auto" /></a>
                                                  </div>
                                              </div>
                                          </div>
                                      </Col>
                                  </Row>
                              </Container>
                          </div>
                      </>
                  }

              <Faq />

              <CallToAction />

          </>
      </Layout>
  );
}

export default Obituary;
