import * as yup from "yup";

const obituaryThirdStepSchema = yup.object({
    first_name: yup.string().required('Indtast venligst fornavn.'),
    last_name: yup.string().required('Indtast venligst efternavn.'),
    email: yup.string().email('Indtast venligst en gyldig e-mailadresse').required('Indtast venligst e-mailadresse.'),
    phone_number: yup.string().required('Indtast venligst telefonnummer.'),
    is_publication: yup.string(),
    // publications: yup.array().min(1, 'Vælg venligst mindst én avis.').of(yup.string().required()).required('Vælg venligst mindst én avis.'),
    publications: yup.string().when("is_publication", {
        is: 'yes',
        then: () => yup.array().min(1, 'Vælg venligst mindst én avis.').of(yup.string().required()).required('Vælg venligst mindst én avis.'),
    })
})

export default obituaryThirdStepSchema;