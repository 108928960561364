import React, { useState, useEffect } from 'react';
import LayoutWithoutHeader from "../../components/Layout/LayoutWithoutHeader";
import Loading from "../../components/Loading";
import styles from '../../styles/step.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import { ReactComponent as PaymentImage } from "../../assets/images/payment-img.svg";
import {Link} from "react-router-dom";

function PaymentSuccess() {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setIsLoading(false);
        })();
    }, [])

  return (
      <LayoutWithoutHeader>
          <>
              {isLoading &&
                  <Loading/>
              }

              {!isLoading &&
                  <>
                      <div className={`${styles['step-container']} w-100 float-left`}>
                          <Container>
                              <Row className={`gx-xl-5`}>
                                  <Col lg={6} className={`order-lg-1 ${styles['step-right']}`}>
                                      <h3>Ofte Stillede Spørgsmål:</h3>
                                      <Accordion defaultActiveKey="0">
                                          <Accordion.Item eventKey="0">
                                              <Accordion.Header>Hvornår kan jeg forvente at se min dødsannonce i avisen?</Accordion.Header>
                                              <Accordion.Body>
                                                  <p>Når betalingen er bekræftet, vil vi straks arrangere indrykningen med den valgte avis. Tidsrammen kan variere, men vi anbefaler at forvente en publicering inden for 2-3 dage.</p>
                                              </Accordion.Body>
                                          </Accordion.Item>
                                          <Accordion.Item eventKey="1">
                                              <Accordion.Header>Hvordan får jeg en bekræftelse på min betaling og indrykning?</Accordion.Header>
                                              <Accordion.Body>
                                                  <p>Efter gennemført betaling sender vi en bekræftelsesmail med alle detaljer om din annonce og den/de avis(er), den vil blive indrykket i.</p>
                                              </Accordion.Body>
                                          </Accordion.Item>
                                          <Accordion.Item eventKey="2">
                                              <Accordion.Header>Kan jeg ændre i min annonce efter betaling?</Accordion.Header>
                                              <Accordion.Body>
                                                  <p>Ændringer kan være mulige inden for en kort tidsramme efter betaling. Kontakt os hurtigst muligt via chat, som er åben dagligt fra 8-21, hvis du har behov for ændringer.</p>
                                              </Accordion.Body>
                                          </Accordion.Item>
                                          <Accordion.Item eventKey="3">
                                              <Accordion.Header>Hvad sker der, hvis der er en fejl i min annonce efter indrykning?</Accordion.Header>
                                              <Accordion.Body>
                                                  <p>Vi stræber efter at undgå fejl, men skulle det ske, er du velkommen til at kontakte os for rettelser. Bemærk, at nogle aviser har deadlines for rettelser.</p>
                                              </Accordion.Body>
                                          </Accordion.Item>
                                          <Accordion.Item eventKey="4">
                                              <Accordion.Header>Er min dødsannonce også tilgængelig online?</Accordion.Header>
                                              <Accordion.Body>
                                                  <p>JA, når du har foretaget betalingen, vil din dødsannonce blive oprettet på Sidstehilsener.dk og være tilgængelig online for evigt, medmindre du vælger at fjerne den.</p>
                                              </Accordion.Body>
                                          </Accordion.Item>
                                      </Accordion>
                                      <div className="btn-row w-100 float-left"><Link to={"/"} className="blue-btn w-100 float-left text-center">Tilbage til Sidstehilsener.dk</Link></div>
                                  </Col>
                                  <Col lg={6} className={`order-lg-0`}>
                                      <div className={`${styles['step-left']} w-100 float-left text-center ${styles['less-pad']}`}>
                                          <div className={`${styles['payment-img']} w-100 float-left`}><PaymentImage className="img-fluid d-block m-auto" />
                                              <div className={`${styles['payment-title']} w-100 float-left horizontal-center`}>Tak for din betaling! 👋</div>
                                              <div className={`${styles['small-title']} w-100 float-left horizontal-center`}>Du vil modtage en bekræftelsesmail med alle detaljer om din annonce og den/de avis(er), den vil blive indrykket i. 👉</div>
                                          </div>
                                      </div>
                                  </Col>
                              </Row>
                          </Container>
                      </div>
                  </>
              }
          </>
      </LayoutWithoutHeader>
  );
}

export default PaymentSuccess;
