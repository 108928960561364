import * as yup from "yup";

const obituarySecondStepSchema = yup.object({
    message_line_1: yup.string().max(50, 'Maksimalt 50 tegn tilladt.'),
    message_line_2: yup.string().max(50, 'Maksimalt 50 tegn tilladt.'),
    message_line_3: yup.string().max(50, 'Maksimalt 50 tegn tilladt.'),
    family_names: yup.string().trim().required('Indtast venligst familienavne.').max(50, 'Maksimalt 50 tegn tilladt.').max(100, 'Maksimalt 50 tegn tilladt.'),
    additional_information: yup.string().trim().max(100, 'Maksimalt 100 tegn tilladt.')
}).required();

export default obituarySecondStepSchema;