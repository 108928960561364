import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from "react-router-dom";
import routes from "./routes/routes";
import { AuthProvider } from 'react-auth-kit'
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/app.scss';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import { HelmetProvider } from "react-helmet-async";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-W5M52W6G'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']} minBreakpoint="xxs">
          <AuthProvider  authType= {'localstorage'} authName={'_auth'}>
              <HelmetProvider>
                <RouterProvider router={routes} />
              </HelmetProvider>
          </AuthProvider>
      </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
