import { Link } from "react-router-dom";
import Logo from '../../../assets/images/logo.png';
import { ReactComponent as HomeIcon } from '../../../assets/images/icon-home.svg';
import { ReactComponent as BlogIcon } from '../../../assets/images/icon-blog.svg';
import { ReactComponent as ContactIcon } from '../../../assets/images/icon-contact.svg';
import { ReactComponent as DonationIcon } from '../../../assets/images/icon-donation.svg';

const Footer = () => {

    return (
        <footer className="w-100 float-left  text-center">
            <div className="container">
                <div className="footer-nav w-100 float-left">
                    <ul>
                        <li><Link to={'/'}><HomeIcon /> Forside</Link></li>
                        <li><Link to={'/blogs'}><BlogIcon /> Blog</Link></li>
                        <li><Link to={'/kontakt-os'}><ContactIcon /> Kontakt os</Link></li>
                        <li><Link to={'/opret-en-dodsannoncen'}><DonationIcon /> Opret en dødsannonce</Link></li>
                    </ul>
                </div>
                <div className="footer-logo w-100 float-left"><img src={Logo} alt="" /></div>
            </div>
        </footer>
    )
}

export default Footer;
