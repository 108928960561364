import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import Layout from "../components/Layout/Layout";
import Loading from "../components/Loading";
import styles from '../styles/home.module.scss';
import Placeholder from "../assets/images/placeholder.jpg";
import { getAll as getAllObituaries } from "../services/Apis/Obituaries.service";
import { format } from 'date-fns';
import Faq from "../components/Faq";
import CallToAction from "../components/CallToAction";
import Carousel from 'react-bootstrap/Carousel';
import {Helmet} from "react-helmet";

function Home() {

    const [isLoading, setIsLoading] = useState(true);
    const [obituaries, setObituaries] = useState<[]>();

    useEffect(() => {
        (async () => {
            const response = await getAllObituaries();
            setObituaries(response);
            setIsLoading(false);
        })();
    }, [])

  return (
      <Layout>
          <Helmet>
              <title>{ process.env.REACT_APP_NAME + ` - Opret Dødsannoncer Nemt og Billigt i Danmark` } </title>
              <meta name="keywords" content="dødsannoncer, begravelse, mindeord, online dødsannoncer, billig dødsannonce" />
              <meta name="description" content="Velkommen til Sidste Hilsener. Vi gør det nemt og billigt at oprette dødsannoncer online. Få din annonce i danske aviser hurtigt og effektivt." />
          </Helmet>

          <>
              {isLoading &&
                  <Loading/>
              }

              {!isLoading &&
                  <>
                      <div className="box-data-row-wrp w-100 float-left">
                          <Container>
                              <h1>Seneste Annoncer</h1>
                              <div className={`${styles['box-data-row']} w-100 float-left`}>
                                  <Row>
                                      {(obituaries !== undefined && obituaries.length) > 0 &&
                                          obituaries?.map((obituary: any, index: number) => (
                                              <Col lg={4} md={6}>
                                                  <div className={`${styles['latest-ad-box']} w-100 float-left`}>
                                                      <div className={`${styles['ad-box-img']} w-100 float-left`}>
                                                          <Link to={"/dodsannoncen/" + obituary.slug}><img src={Placeholder} alt="" /></Link>
                                                      </div>
                                                      <div className={`${styles['ad-box-bottom']} w-100 float-left`}>
                                                          <div className={`${styles['ad-box-year']} w-100 float-left`}>
                                                              { obituary.birth_date != '' ? format(new Date(obituary.birth_date), 'yyyy')+ ' - ' : '' }{format(new Date(obituary.departed_date), 'yyyy')}
                                                          </div>
                                                          <h2><Link to={"/dodsannoncen/" + obituary.slug}>{ obituary.full_name }</Link></h2>
                                                          <div className={`${styles['city-name']}`}>{ obituary.city }</div>
                                                      </div>
                                                  </div>
                                              </Col>
                                          ))
                                      }

                                      {(obituaries !== undefined && obituaries.length) <= 0 &&
                                          <>
                                                <Col lg={12}>
                                                    <h3>Seneste dødsannoncer vises her.</h3>
                                                </Col>
                                          </>
                                      }
                                  </Row>
                              </div>
                          </Container>
                      </div>

                      <Faq />

                      <CallToAction />
                  </>
              }
          </>
      </Layout>
  );
}

export default Home;
