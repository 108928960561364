import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import LayoutWithoutHeader from "../../components/Layout/LayoutWithoutHeader";
import Loading from "../../components/Loading";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import { create as createObituary } from "../../services/Apis/Obituaries.service";
import {Helmet} from "react-helmet";

function CreateObituary() {

    const [isLoading, setIsLoading] = useState(true);
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState<any>({});

    const handleStepData = async (data: any) => {
        let updatedData = {};
        Object.assign(updatedData, formData, data);
        setFormData(updatedData);

        if (step >= 3) {
            const response = await createObituary(updatedData);
            window.location.href = response.payment_link;
        }

        let stepNumber = handleSteps('next');
    }

    const handleSteps = (type = 'next') => {
        let currentStep = 1;

        if (type === 'next') {
            currentStep = (step + 1) >= 3 ? 3 : (step + 1);
            setStep(currentStep);
        } else {
            currentStep = (step - 1) <= 1 ? 1 : (step - 1);
            setStep(currentStep);
        }

        return currentStep;
    }

    useEffect(() => {
        (async () => {
            setIsLoading(false);
        })();
    }, [])

    return (
        <LayoutWithoutHeader>
            <>
                <Helmet>
                    <title>{ `Opret en Dødsannonce - ` + process.env.REACT_APP_NAME + ` gør det nemt` } </title>
                    <meta name="keywords" content="opret dødsannonce, online dødsannoncer, billig dødsannonce, mindeord" />
                    <meta name="description" content="Opret en personlig og betydningsfuld dødsannonce online hos Sidste Hilsener. Det er hurtigt, nemt og den billigste løsning på markedet." />
                </Helmet>

                {isLoading &&
                    <Loading/>
                }

                {!isLoading &&
                    <>
                        {step === 1 &&
                            <FirstStep handleStepData={handleStepData} formData={formData} handleSteps={handleSteps} />
                        }

                        {step === 2 &&
                            <SecondStep handleStepData={handleStepData} formData={formData} handleSteps={handleSteps} />
                        }

                        {step === 3 &&
                            <ThirdStep handleStepData={handleStepData} formData={formData} handleSteps={handleSteps} />
                        }
                    </>
                }
            </>
        </LayoutWithoutHeader>
    );
}

export default CreateObituary;
