import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import TrustPilotLogo from '../../../assets/images/logo-trustpilot.png';

const Banner = () => {

    return (
        <div className="banner">
            <Container>
                <div className="banner-image w-100 float-left">
                    <div className="banner-content">
                        <h3>Opret og publicer dødsannoncer hurtigt og nemt – uden at tømme pengepungen.</h3>
                        <p>For kun 99 kr. kan du oprette en dødsannonce på SidsteHilsener.dk, der bliver online for evigt. Indryk den også i enhver landsdækkende avis til samme pris som aviserne selv kræver — enkel og hurtig via én samlet platform.</p>
                    </div>
                    <div className="button-row w-100 float-left">
                        <Link to={'/opret-en-dodsannoncen'} className="float-left banner-btn">Opret en dødsannonce</Link>
                    </div>
                    <div className="banner-rating">
                        <img src={TrustPilotLogo} />
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Banner;
