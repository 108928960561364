import axios from "../../lib/axios";

export async function getPaymentDetails(invoice, data) {
    const response = await axios.post('/obituaries/payments/'+invoice, data);
    return response.data;
}

export async function getDetails(id) {
    const response = await axios.get('/obituaries/details/'+id);
    return response.data;
}

export async function makePayment(id) {
    const response = await axios.post('/obituaries/'+id+'/make-payment');
    return response.data;
}

