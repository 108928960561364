import { createBrowserRouter } from "react-router-dom";
import Home from '../pages/Home';
import CreateObituary from "../pages/Obituary/CreateObituary";
import Obituary from "../pages/Obituary/Obituary";
import PaymentSuccess from "../pages/Obituary/PaymentSuccess";
import PaymentCancel from "../pages/Obituary/PaymentCancel";
import ContactUs from "../pages/ContactUs";
import Blog from '../pages/Blog';
import BlogDetail from "../pages/BlogDetail";
import Payment from "../pages/Obituary/Payment";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/blogs",
    element: <Blog />,
  },
  {
    path: "/blogs/:slug",
    element: <BlogDetail />,
  },
  {
    path: "/kontakt-os",
    element: <ContactUs />,
  },
  {
    path: "/dodsannoncen/:slug",
    element: <Obituary />,
  },
  {
    path: "/opret-en-dodsannoncen/payment/success",
    element: <PaymentSuccess />,
  },
  {
    path: "/opret-en-dodsannoncen/payment/cancel",
    element: <PaymentCancel />,
  },
  {
    path: "/opret-en-dodsannoncen",
    element: <CreateObituary />,
  },
  {
    path: "/opret-en-dodsannoncen/payment/:id",
    element: <Payment />,
  }
]);

export default router;