import React, { useState, useEffect } from 'react';
import LayoutWithoutHeader from "../../components/Layout/LayoutWithoutHeader";
import Loading from "../../components/Loading";
import styles from '../../styles/step.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import { getDetails } from "../../services/Apis/Payments.service";
import IObituary from "../../interfaces/IObituary";
import {useParams} from "react-router-dom";
import { format } from "date-fns";
import { makePayment as getPaymentLink } from "../../services/Apis/Payments.service";

function Payment() {

    const [isLoading, setIsLoading] = useState(true);
    const [obituary, setObituary] = useState<IObituary>();

    const { id } = useParams()

    const makePayment = async () => {
        const response = await getPaymentLink(id);
        window.location.href = response.payment_link;
    }

    useEffect(() => {
        (async () => {
            const response = await getDetails(id);
            setObituary(response);
            setIsLoading(false);
        })();
    }, [])

  return (
      <LayoutWithoutHeader>
          <>
              {isLoading &&
                  <Loading/>
              }

              {!isLoading &&
                  obituary !== undefined &&
                  <>
                      <div className={`${styles['step-container']} w-100 float-left`}>
                          <Container>
                              <Row className={`gx-xl-5`}>
                                  <Col lg={6} className={`order-lg-1 ${styles['step-right']} text-center`}>
                                      <h3>Foretag betaling</h3>
                                      <p>Gennemgå venligst din annonce, og klik på linket for at foretage betaling.</p>
                                      <div className="btn-row w-100 float-left"><button onClick={(e) => makePayment()} className="blue-btn w-100 float-left text-center">Foretag betaling</button></div>
                                  </Col>
                                  <Col lg={6} className={`order-lg-0`}>
                                      <div className={`${styles['step-left']} w-100 float-left text-center ${styles['with-shadow-border']}`}>
                                          <div className={`${styles['step-2-left-info']} w-100 float-left text-center`}>
                                              <ul>
                                                  <li>{ obituary.message_line_1 != '' ? obituary.message_line_1 : '❤' }</li>
                                                  <li>
                                                      <span className={styles['large-text']}>{ obituary.full_name }</span>
                                                      <br /> { obituary.message_line_2 }<br />
                                                      <span className={styles['large-text']}>{ obituary?.birth_date != null ? format(new Date(obituary.birth_date), 'dd.MM.yyyy') + ' - ' : ''  } { format(new Date(obituary.departed_date), 'dd.MM.yyyy') }</span>
                                                  </li>
                                                  <li> { obituary.message_line_3 }<br />
                                                      <strong>{ obituary.family_names }</strong>
                                                  </li>
                                                  <li>{ obituary.type == 'funeral' ? 'Begravelse' : 'Bisættelse' } finder sted i: { obituary.address } -<br />
                                                      { format(new Date(obituary.funeral_date), 'dd.MM.yyyy') } - Kl. { format(new Date(obituary.funeral_date+' '+obituary.funeral_time), 'h:mm aa') }
                                                  </li>
                                                  <li>{ obituary.additional_information }</li>
                                              </ul>
                                          </div>
                                      </div>
                                  </Col>
                              </Row>
                          </Container>
                      </div>
                  </>
              }
          </>
      </LayoutWithoutHeader>
  );
}

export default Payment;
