import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Logo from '../../../assets/images/logo.png';
import { ReactComponent as HomeIcon } from '../../../assets/images/icon-home.svg';
import { ReactComponent as BlogIcon } from '../../../assets/images/icon-blog.svg';
import { ReactComponent as ContactIcon } from '../../../assets/images/icon-contact.svg';
import { ReactComponent as DonationIcon } from '../../../assets/images/icon-donation.svg';
import { Navbar, Nav } from "react-bootstrap";

const Header = () => {

    return (
        <header className="w-100 float-left">
            <div className="container">
                <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                    <Link to={"/"} className={`navbar-brand`}><img src={Logo} alt="" /></Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto mb-2 mb-lg-0" as={"ul"}>
                            <Nav.Item as="li"><Link to="/"><HomeIcon /> Forside</Link></Nav.Item>
                            <Nav.Item as="li"><Link to="/blogs"><BlogIcon /> Blog</Link></Nav.Item>
                            <Nav.Item as="li"><Link to="/kontakt-os"><ContactIcon /> Kontakt os</Link></Nav.Item>
                            <Nav.Item as="li" className="last-btn"><Link to="/opret-en-dodsannoncen">&nbsp;&nbsp;<DonationIcon />&nbsp;&nbsp;Opret en dødsannonce</Link></Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </header>
    )
}

export default Header;
