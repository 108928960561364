import styles from '../styles/loading.module.scss';

export default function Loading() {

    return (
        <>
            <div className={styles['loading-position']}>
                <div className={styles['loading-container']}>
                    <div className={`${styles['loading-text']} text-center`}>
                        <span>L</span>
                        <span>O</span>
                        <span>A</span>
                        <span>D</span>
                        <span>I</span>
                        <span>N</span>
                        <span>G</span>
                    </div>
                </div>
            </div>
        </>
    )
}
