import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import Layout from "../components/Layout/Layout";
import styles from '../styles/blog.module.scss';
import CallToAction from "../components/CallToAction";
import {getAll as getBlogs} from "../services/Apis/Blogs.service";
import Loading from "../components/Loading";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

function Blog() {

    const [isLoading, setIsLoading] = useState(true);
    const [blogs, setBlogs] = useState<[]>();

    useEffect(() => {
        (async () => {
            const response = await getBlogs();
            setBlogs(response);
            setIsLoading(false);
        })();
    }, [])

  return (
      <Layout>
          <>
              <Helmet>
                  <title>{ process.env.REACT_APP_NAME + ` Blog - Nyttige artikler om dødsannoncer og begravelser` } </title>
                  <meta name="keywords" content="dødsannonce tips, begravelsesplanlægning, mindeord, obituary guide, begravelsesmusik, begravelsesguide" />
                  <meta name="description" content="Vores blog tilbyder værdifulde tips og vejledninger til alt fra dødsannoncer til begravelsesplanlægning. Få den hjælp og information, du har brug for i denne svære tid." />
              </Helmet>

              {isLoading &&
                  <Loading />
              }

              {!isLoading &&
                  <>
                      <div className={`${styles['blog-top']} w-100 float-left`}>
                          <Container>
                              <h1>Læs alt om Dødsannoncer på vores Blog</h1>
                              <div className={`${styles['blog-data-row']} w-100 float-left`}>
                                  <Row className={`gx-lg-5`}>

                                      {(blogs !== undefined && blogs.length) < 0 &&
                                        <>
                                            <Col md={12} className={`text-center`}>
                                                <h4>No blogs to display.</h4>
                                            </Col>
                                        </>
                                      }

                                      {(blogs !== undefined && blogs.length) > 0 &&
                                          blogs?.map((blog: any, index: number) => (
                                              <Col md={6}>
                                                  <div className={`${styles['blog-box']} w-100 float-left`}>
                                                      <div className={`${styles['blog-img']} w-100 float-left`}>
                                                          <img src={blog.image} alt={blog.title} />
                                                      </div>
                                                      <div className={`${styles['blog-info']} w-100 float-left`}>
                                                          <a href="#">{ blog.author }</a> | { blog.date }
                                                      </div>
                                                      <h2><Link to={`/blogs/${blog.slug}`}>{ blog.title }</Link></h2>
                                                      <p>{ blog.short_description }</p>
                                                  </div>
                                              </Col>
                                          ))
                                      }
                                  </Row>
                              </div>
                          </Container>
                      </div>
                  </>
              }
              <CallToAction />
          </>
      </Layout>
  );
}

export default Blog;
