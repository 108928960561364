import React, { useState, useEffect } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import styles from '../../styles/step.module.scss';
import { ReactComponent as HeartCircle } from '../../assets/images/heart-circle.svg';
import { ReactComponent as RightArrow } from '../../assets/images/right-arrow.svg';
import obituaryFirstStepSchema from "../../services/Schemas/ObituaryFirstStepSchema";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from "date-fns";
import da from "date-fns/locale/da";

function ObituaryFirstStep({handleStepData, formData, handleSteps}: any) {

    const [birthDate, setBirthDate] = useState<Date | undefined>();
    const [departedDate, setDepartedDate] = useState<Date>();
    const [funeralDate, setFuneralDate] = useState<Date>();
    // const [funeralTime, setFuneralTime] = useState<Date>();

    const { register, watch, setValue, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(obituaryFirstStepSchema)
    });

    const handleBirthDate = (date: Date | undefined) => {
        setBirthDate(date);
        setValue('birth_date', date);
    }

    const handleDepartedDate = (date: Date) => {
        setDepartedDate(date);
        setValue('departed_date', date);
    }

    const handleFuneralDate = (date: Date) => {
        setFuneralDate(date);
        setValue('funeral_date', date);
    }

    // const handleFuneralTime = (date: Date) => {
    //     setFuneralTime(date);
    //     setValue('funeral_time', date);
    // }

    const handleSubmission = async (data: any) => {
        handleStepData(data);
    }

    const setFormValues = async () => {
        if (formData.full_name !== undefined) {
            setValue('full_name', formData.full_name);
            setValue('birth_date', formData.birth_date);
            setBirthDate(formData.birth_date);
            setValue('departed_date', formData.departed_date);
            setDepartedDate(formData.departed_date);
            setValue('city', formData.city);
            setValue('funeral_date', formData.funeral_date);
            setFuneralDate(formData.funeral_date);
            // setValue('funeral_time', formData.funeral_time);
            // setFuneralTime(formData.funeral_time);
            setValue('address', formData.address);
            setValue('type', formData.type);
        }
    }

    useEffect(() => {
        setFormValues();
    }, []);

    return (
        // <LayoutWithoutHeader>
        //     <>
        //         {isLoading &&
        //             <Loading/>
        //         }
        //
        //         {!isLoading &&
                    <>
                        <div className={`${styles['step-container']} w-100 float-left`}>
                            <Container>
                                <Row className={`gx-xl-5`}>
                                    <Col lg={6} className={`order-lg-1 ${styles['step-right']}`}>
                                        <h1>Opret Annoncen</h1>
                                        <p>Trin 1: Udfyld venligst med oplysinger om <br/> den afdøde.</p>

                                        <form onSubmit={handleSubmit(handleSubmission)}>
                                            <div className={`${styles['form-section']} w-100 float-left`}>
                                                <div className={`form-group w-100 float-left`}>
                                                    <label>NAVN PÅ DEN AFDØDE *</label>
                                                    <div className={`input-box w-100 float-left with-icon`}>
                                                        <i className={`form-icon-1`}></i>
                                                        <input maxLength={50} type="text" placeholder="FULDE NAVN" className="form-control" {...register("full_name")} />
                                                        <span className="invalid-feedback-error">{errors?.full_name?.message}</span>
                                                    </div>
                                                </div>

                                                <div className={`form-group w-100 float-left`}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <label>FØDT DATO</label>
                                                            <div className={`input-box w-100 float-left with-icon`}>
                                                                <i className={`form-icon-2`}></i>
                                                                <DatePicker locale={da} isClearable placeholderText={'25.08.1975'} className="form-control" selected={birthDate} onChange={(date: Date) => handleBirthDate(date)} dateFormat={'dd.MM.yyyy'} showFullMonthYearPicker maxDate={new Date()} />
                                                                <span className="invalid-feedback-error">{errors?.birth_date?.message}</span>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="column-view">
                                                            <label>GIK BORT DATO *</label>
                                                            <div className={`input-box w-100 float-left with-icon`}>
                                                                <i className={`form-icon-2`}></i>
                                                                <DatePicker locale={da} placeholderText={ format(new Date(), 'dd.MM.yyyy') } className="form-control" selected={departedDate} onChange={(date: Date) => handleDepartedDate(date)} dateFormat={'dd.MM.yyyy'} maxDate={new Date()} />
                                                                <span className="invalid-feedback-error">{errors?.departed_date?.message}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className={`form-group w-100 float-left`}>
                                                    <label>BY *</label>
                                                    <div className={`input-box w-100 float-left with-icon`}>
                                                        <i className={`form-icon-2`}></i>
                                                        <input type="text" placeholder="By" className="form-control" {...register("city")} />
                                                        <span className="invalid-feedback-error">{errors?.city?.message}</span>
                                                    </div>
                                                </div>

                                                <div className={`form-group w-100 float-left fancy-radio`}>
                                                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                        <input type="radio" className="btn-check" id="funeral" value={'funeral'} autoComplete="off" {...register("type")} checked={true} />
                                                        <label className="btn btn-outline-primary" htmlFor="funeral">Begravelse <span className="circle"></span></label>
                                                        <input type="radio" className="btn-check" id="burial" value={'burial'} autoComplete="off" {...register("type")} />
                                                        <label className="btn btn-outline-primary" htmlFor="burial">Bisættelse <span className="circle"></span></label>
                                                    </div>
                                                </div>

                                                 <p>Hvis der holdes en begravelse eller bisættelse, må du gerne angive sted og dato for mindehøjtiden.</p>

                                                <div className={`form-group w-100 float-left`}>
                                                    <label>Dato for mindehøjtiden *</label>
                                                    <div className={`input-box w-100 float-left with-icon`}>
                                                        <i className={`form-icon-2`}></i>
                                                        <DatePicker timeCaption={'Tid'} locale={da} placeholderText={'DATO'} showTimeSelect className="form-control" selected={funeralDate} onChange={(date: Date) => handleFuneralDate(date)} dateFormat={'dd.MM.yyyy  H:mm'} minDate={new Date()} />
                                                        <span className="invalid-feedback-error">{errors?.funeral_date?.message}</span>
                                                    </div>
                                                </div>

                                                <div className={`form-group w-100 float-left`}>
                                                    <label>Sted for mindehøjtiden *</label>
                                                    <div className={`input-box w-100 float-left with-icon`}>
                                                        <i className={`form-icon-3`}></i>
                                                        <input type="text" placeholder="STED" className="form-control" {...register("address")} />
                                                        <span className="invalid-feedback-error">{errors?.address?.message}</span>
                                                    </div>
                                                </div>

                                                <div className="btn-row w-100 float-left">
                                                    <button className="blue-btn w-100 float-left">FORTSÆT<RightArrow /></button>
                                                </div>
                                            </div>
                                        </form>
                                    </Col>

                                    <Col lg={6} className={`order-lg-0`}>
                                        <div className={`${styles['step-left']} w-100 float-left text-center`}>
                                            <div className={`${styles['info-box']} w-100 float-left`}>
                                                <HeartCircle />
                                                <span className="w-100 float-left">100% tilfredhedsgaranti</span>
                                            </div>
                                            <div className={`${styles['info-box']} w-100 float-left`}>
                                                <HeartCircle />
                                                <span className="w-100 float-left">Nemt &amp; overskueligt</span>
                                            </div>
                                            <div className={`${styles['info-box']} w-100 float-left`}>
                                                <HeartCircle />
                                                <span className="w-100 float-left">Gratis support &amp; vejledning</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </>
        //         }
        //     </>
        // </LayoutWithoutHeader>
    );
}

export default ObituaryFirstStep;
