import { useLocation } from "react-router-dom";
import Header from './components/Header';
import Footer from "./components/Footer";
import styles from '../../styles/ad-view.module.scss';
import contactStyles from '../../styles/contact.module.scss';
import Banner from "./components/Banner";

const Layout = ({ children }: any) => {

    const location = useLocation();
    const isAdPage = location.pathname.indexOf('/dodsannoncen') >= 0;
    const isContactPage = location.pathname.indexOf('/kontakt-os') >= 0;
    const isHomePage = location.pathname === "/";

    return (
        <>
            <div id="wrapper" className="w-100 float-left">
                <Header />

                {isHomePage &&
                    <Banner />
                }

                <div className={`w-100 float-left middle ${isAdPage ? styles['adview-container'] : ''} ${isContactPage ? contactStyles['contact-container'] : ''}`}>
                    { children }
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Layout;
