import { useLocation } from "react-router-dom";

const LayoutWithoutHeader = ({ children }: any) => {

    const location = useLocation();

    return (
        <>
            <div id="wrapper" className="w-100 float-left">
                { children }
            </div>
        </>
    )
}

export default LayoutWithoutHeader;
