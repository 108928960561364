import axios from "../../lib/axios";

export async function getAll() {
    const response = await axios.post('/blogs');
    return response.data;
}

export async function get(slug) {
    const response = await axios.post('/blogs/'+slug);
    return response.data;
}
