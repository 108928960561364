import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import FaqImage from "../assets/images/faq-img.png";
import Accordion from "react-bootstrap/Accordion";
import React from "react";
import styles from '../styles/home.module.scss';

export default function Faq() {

    return (
        <>
            <div className={`${styles['faq-section']} w-100 float-left`}>
                <Container>
                    <Row>
                        <Col lg={6} className={`order-lg-1`}>
                            <h3>Ofte Stillede Spørgsmål</h3>
                            <div className={styles['faq-img']}><img src={FaqImage} alt="" /></div>
                        </Col>
                        <Col lg={6} className={`order-lg-0`}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Hvad koster det at oprette en dødsannonce på SidsteHilsener?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Det koster kun 99 kr. at oprette en online dødsannonce på vores platform. Dette inkluderer en evig online præsentation af annoncen.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Kan jeg indrykke min dødsannonce i en avis via SidsteHilsener?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>JA, og vi opkræver præcis det samme, som avisen selv gør. På Sidstehilsener har vi samlet alle landsdækkende og lokale aviser under ét tag, så du nemt kan se de direkte avistakster. Skulle du finde en annonceplads billigere direkte hos avisen, prismatcher vi uden tøven. Og det bedste af det hele? Emma fra Sidstehilsener står for korrekturlæsning og tager sig af alt det praktiske vedrørende indrykningen, så du ikke behøver at oprette flere profiler eller gennemgå besværlige trin.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Hvordan fungerer betalingen?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Betalingen sker sikkert online via Kort, MobilePay eller ApplePay. Der er ingen skjulte gebyrer eller omkostninger.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Hvor lang tid tager det at oprette og indrykke en dødsannonce?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Det tager kun få minutter at oprette en online dødsannonce. Tidspunktet for indrykning i avisen afhænger af avisens egne deadlines, men processen er designet til at være så hurtig som mulig.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Er min dødsannonce synlig online for altid?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Ja, din online dødsannonce vil være tilgængelig for evigt, så du og dine nærmeste altid kan vende tilbage og mindes.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
