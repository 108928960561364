import React, { useEffect } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import styles from '../../styles/step.module.scss';
import { ReactComponent as RightArrow } from '../../assets/images/right-arrow.svg';
import obituarySecondStepSchema from "../../services/Schemas/ObituarySecondStepSchema";
import { ReactComponent as BackArrow } from "../../assets/images/back-arrow-left.svg";
import { format } from "date-fns";

function ObituarySecondStep({handleStepData, formData, handleSteps}: any) {

    const { register, watch, setValue, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(obituarySecondStepSchema)
    });

    const handleSubmission = async (data: any) => {
        handleStepData(data);
    }

    const setFormValues = async () => {
        if (formData.message_line_1 !== undefined) {
            setValue('message_line_1', formData.message_line_1);
            setValue('message_line_2', formData.message_line_2);
            setValue('message_line_3', formData.message_line_3);
            setValue('family_names', formData.family_names);
            setValue('additional_information', formData.additional_information);
        } else {
            // setValue('message_line_2', 'Er afgået ved døden.');
            // setValue('message_line_3', 'På familiens vegne');
            // setValue('additional_information', 'Donér et beløb til Red Barnet, i stedet for at sende blomster.');
        }
    }

    useEffect(() => {
        setFormValues();
    }, []);

    return (
        // <LayoutWithoutHeader>
        //     <>
        //         {isLoading &&
        //             <Loading/>
        //         }
        //
        //         {!isLoading &&
        //             <>
                        <div className={`${styles['step-container']} w-100 float-left`}>
                            <Container>
                                <Row className={`gx-xl-5`}>
                                    <Col lg={6} className={`order-lg-1 ${styles['step-right']}`}>
                                        <h1>Hilsener</h1>

                                        <form onSubmit={handleSubmit(handleSubmission)}>
                                            <div className={`${styles['form-section']} w-100 float-left`}>
                                                <div className={`form-group w-100 float-left`}>
                                                    <label>Mindetekst *</label>
                                                    <div className={`input-box w-100 float-left with-icon`}>
                                                        <i className={`form-icon-4`}></i>
                                                        <input type="text" className="form-control" {...register("message_line_1")} maxLength={50} />
                                                        <span className="invalid-feedback-error">{errors?.message_line_1?.message}</span>
                                                    </div>
                                                    <div className={`input-box w-100 float-left with-icon`}>
                                                        <i className={`form-icon-4`}></i>
                                                        <input type="text" placeholder={"Er afgået ved døden."} className="form-control" {...register("message_line_2")} maxLength={50} />
                                                        <span className="invalid-feedback-error">{errors?.message_line_2?.message}</span>
                                                    </div>
                                                    <div className={`input-box w-100 float-left with-icon`}>
                                                        <i className={`form-icon-4`}></i>
                                                        <input type="text" placeholder="På familiens vegne" className="form-control" {...register("message_line_3")} maxLength={50} />
                                                        <span className="invalid-feedback-error">{errors?.message_line_3?.message}</span>
                                                    </div>
                                                </div>

                                                <div className={`form-group w-100 float-left`}>
                                                    <label>Navne på familie / venner:</label>
                                                    <div className={`input-box w-100 float-left with-icon`}>
                                                        <i className={`form-icon-1`}></i>
                                                        <textarea maxLength={100} className="form-control" rows={2} placeholder={'Camila, Thomas & Nicole Hansen'} {...register("family_names")}></textarea>
                                                        <span className="invalid-feedback-error">{errors?.family_names?.message}</span>
                                                    </div>
                                                </div>

                                                <div className={`form-group w-100 float-left`}>
                                                    <label>Andet info:</label>
                                                    <div className={`input-box w-100 float-left with-icon`}>
                                                        <i className={`form-icon-5`}></i>
                                                        <textarea placeholder={"Donér et beløb til Røde Kors i stedet for sende blomster."} maxLength={100} className="form-control" rows={2} {...register("additional_information")}></textarea>
                                                        <span className="invalid-feedback-error">{errors?.additional_information?.message}</span>
                                                    </div>
                                                </div>

                                                <div className="btn-row w-100 float-left">
                                                    <button type={"submit"} className="blue-btn multiple-buttons float-right">FORTSÆT <RightArrow /></button>
                                                    <button onClick={(e) => handleSteps('previous')} className={`${styles['back-btn']} float-left`}><BackArrow /> Tilbage</button>
                                                </div>
                                            </div>
                                        </form>
                                    </Col>

                                    <Col lg={6} className={`order-lg-0`}>
                                        <div className={`${styles['step-left']} w-100 float-left text-center ${styles['with-shadow-border']}`}>
                                            <div className={`${styles['step-2-left-info']} w-100 float-left text-center`}>
                                                <ul>
                                                    <li>{ watch('message_line_1') != '' ? watch('message_line_1') : '❤' }</li>
                                                    <li>
                                                        <span className={styles['large-text']}>{ formData?.full_name }</span>
                                                        <br /> { watch('message_line_2') != '' ? watch('message_line_2') : 'Er afgået ved døden.' }<br />
                                                        <span className={styles['large-text']}>{ (formData?.birth_date !== undefined && formData?.birth_date !== null) ? format(formData.birth_date, 'dd.MM.yyyy') + ' - ' : ''  } { format(formData.departed_date, 'dd.MM.yyyy') }</span>
                                                    </li>
                                                    <li> { watch('message_line_3') ? watch('message_line_3') : 'På familiens vegne' }<br />
                                                        <strong>{ watch('family_names') }</strong>
                                                    </li>
                                                    <li>{ formData.type == 'funeral' ? 'Begravelse' : 'Bisættelse' } finder sted i { formData?.address } -<br />{ format(formData.funeral_date, 'dd.MM.yyyy') } - Kl. { format(formData.funeral_date, 'H:mm') }</li>
                                                    <li>{ watch('additional_information') != '' ? watch('additional_information') : 'Donér et beløb til Røde Kors i stedet for sende blomster.' }</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
        //             </>
        //         }
        //     </>
        // </LayoutWithoutHeader>
    );
}

export default ObituarySecondStep;
