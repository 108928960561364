import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import Layout from "../components/Layout/Layout";
import styles from '../styles/contact.module.scss';
import MapImage from "../assets/images/map-2.jpg";
import Faq from "../components/Faq";
import CallToAction from "../components/CallToAction";
import { ReactComponent as TelephoneIcon } from "../assets/images/icon-tel.svg";
import { ReactComponent as ChatIcon } from "../assets/images/icon-chat-2.svg";
import { ReactComponent as EmailIcon } from "../assets/images/icon-email-2.svg";
import {Helmet} from "react-helmet";

function ContactUs() {

  return (
      <Layout>
          <>
              <Helmet>
                  <title>{ `Kontakt ` + process.env.REACT_APP_NAME + ` - Vi er her for dig` } </title>
                  <meta name="keywords" content="kontakt, kundeservice, dødsannoncer, begravelse, hjælp" />
                  <meta name="description" content="Har du spørgsmål eller brug for hjælp med din dødsannonce? Kontakt os for personlig og omsorgsfuld kundeservice." />
              </Helmet>

              <div className={`${styles['contact-top']} w-100 float-left`}>
                  <Container>
                      <Row>
                            <Col lg={6} className={`contact-left`}>
                                <h1>Send os en <span>Besked</span></h1>
                                <p>Vores online chat er åben dagligt fra kl. 8-21 for at besvare dine spørgsmål og guide dig gennem hele processen med at oprette og indrykke dødsannoncer.</p>

                                <div className={`${styles['form-section']} w-100 float-left`}>
                                    <div className="form-group"><input type="text" placeholder="Navn *" className="form-control" /></div>
                                    <div className="form-group"><input type="text" placeholder="Email *" className="form-control" /></div>
                                    <div className="form-group"><input type="text" placeholder="Telefonnummer *" className="form-control" /></div>
                                    <div className="form-group">
                                        <select className="form-select">
                                            <option>How did you find us?</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <button className="w-100 float-left pink-btn">send</button>
                                    </div>
                                </div>

                                <div className={`${styles['contact-detail-row']} w-100 float-left`}>
                                    <Row>
                                        <Col md={4} lg={12} xl={4}>
                                            <div className={`${styles['contact-box']} w-100 float-left`}>
                                                <TelephoneIcon /> VI ER ONLINE DAGLIGT<br /><a href="#">8:00 - 21:00</a>
                                            </div>
                                        </Col>
                                        <Col md={4} lg={12} xl={4}>
                                            <div className={`${styles['contact-box']} w-100 float-left`}>
                                                <ChatIcon /> CHAT<br /><a href="#">Chat med os</a>
                                            </div>
                                        </Col>

                                        <Col md={4} lg={12} xl={4}>
                                            <div className={`${styles['contact-box']} w-100 float-left`}>
                                                <EmailIcon /> EMAIL<br /><a href="mailto:hej@sidstehilsener.dk">hej@sidstehilsener.dk</a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <img src={MapImage} alt="" className="img-fluid d-block m-auto" />
                            </Col>
                      </Row>
                  </Container>
              </div>

              <Faq />
              <CallToAction />
          </>
      </Layout>
  );
}

export default ContactUs;
