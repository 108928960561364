import React, { useState, useEffect, useRef } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import styles from '../../styles/step.module.scss';
import { ReactComponent as RightArrow } from '../../assets/images/right-arrow.svg';
import obituaryThirdStepSchema from "../../services/Schemas/ObituaryThirdStepSchema";
import { Dropdown } from "react-bootstrap";
import IPublications from "../../interfaces/IPublications";
import { getAll } from "../../services/Apis/Publications.service";
import {ReactComponent as BackArrow} from "../../assets/images/back-arrow-left.svg";
import PaymentMobilePayLogo from '../../assets/images/payment-mobilepay.png';
import PaymentMasterCardLogo from '../../assets/images/payment-mastercard.png';
import PaymentVisaLogo from '../../assets/images/payment-visa.png';
import TrustPilotStars from '../../assets/images/trust-pilot-stars.png';

function ObituaryThirdStep({handleStepData, formData, handleSteps}: any) {

    const [publications, setPublications] = useState<IPublications[]>();
    const [checkedPublications, setCheckedPublications] = useState<boolean[]>();
    const [paymentAmount, setPaymentAmount] = useState<number>(99);
    const [selectedPublications, setSelectedPublications] = useState<string>('');

    const effectRan = useRef(false)

    const { register, watch, setValue, getValues, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(obituaryThirdStepSchema)
    });

    const handleSubmission = async (data: any) => {
        handleStepData(data);
    }

    const setFormValues = async () => {
        if (formData.first_name) {
            setValue('first_name', formData.first_name);
            setValue('last_name', formData.last_name);
            setValue('email', formData.email);
            setValue('phone_number', formData.phone_number);
            setValue('is_publication', formData.is_publication);
        } else {
            setValue('is_publication', 'yes');
        }
    }

    const handlePublications = (position: number) => {
        const updatedCheckedState = checkedPublications?.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedPublications(updatedCheckedState);
    }

    const handleAmountCalculations = () => {

        let amount = 99;
        let publicationNames = '';

        checkedPublications?.map(function (isChecked, index) {
            if (isChecked === true) {
                if (publications !== undefined) {
                    amount += Number(publications[index].price);
                    publicationNames = publicationNames != '' ? publicationNames + ', ' + publications[index].name : publications[index].name;
                }
            }
        });

        setSelectedPublications(publicationNames);
        setPaymentAmount(amount);
    }

    useEffect(() => {
        setFormValues();
        (async () => {
            const response = await getAll();
            setPublications(response);
            setCheckedPublications(new Array(response.length).fill(false));
        })();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (effectRan.current === false) {
                effectRan.current = true;
            } else {
                handleAmountCalculations()
            }
        }, 500)

        return () => {
            clearTimeout(timer)
        }

    }, [checkedPublications]);

    return (
        <div className={`${styles['step-container']} w-100 float-left`}>
            <Container>
                <Row className={`gx-xl-5`}>
                    <Col lg={6} className={`order-lg-1 ${styles['step-right']}`}>
                        <h1>Opret Annoncen</h1>
                        <p>Vi skal bruge navn &amp; email på dig som<br /> opretter annoncen.</p>

                        <form onSubmit={handleSubmit(handleSubmission)}>
                            <div className={`${styles['form-section']} w-100 float-left`}>
                                <Row>
                                    <Col md={6}>
                                        <div className="form-group w-100 float-left">
                                            <label>FORNAVN:</label>
                                            <div className={`input-box w-100 float-left with-icon`}>
                                                <i className="form-icon-1"></i>
                                                <input type="text" placeholder={"FORNAVN"} className="form-control" {...register("first_name")} />
                                                <span className="invalid-feedback-error">{errors?.first_name?.message}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group w-100 float-left">
                                            <label>EFTERNAVN:</label>
                                            <div className={`input-box w-100 float-left with-icon`}>
                                                <i className="form-icon-1"></i>
                                                <input type="text" placeholder={"EFTERNAVN"} className="form-control" {...register("last_name")} />
                                                <span className="invalid-feedback-error">{errors?.last_name?.message}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="form-group w-100 float-left">
                                    <label>EMAIL:</label>
                                    <div className={`input-box w-100 float-left with-icon`}>
                                        <i className="form-icon-6"></i>
                                        <input placeholder={"EMAIL"} type="text" className="form-control" {...register("email")} />
                                        <span className="invalid-feedback-error">{errors?.email?.message}</span>
                                    </div>
                                </div>

                                <div className="form-group w-100 float-left">
                                    <label>TELEFON NR:</label>
                                    <div className={`input-box w-100 float-left with-icon`}>
                                        <i className="form-icon-2"></i>
                                        <input placeholder={"80 80 80 80"} type="text" className="form-control" {...register("phone_number")} />
                                        <span className="invalid-feedback-error">{errors?.phone_number?.message}</span>
                                    </div>
                                </div>

                                <div className="form-group w-100 float-left">
                                    <div className={`${styles['circle-radio']} w-100 float-left`}> SKAL ANNONCEN INDRYKKES I EN AVIS?
                                        <div className="btn-group" role="group">
                                            <input type="radio" className={`${styles['btn-check']} btn-check`} value={"yes"} id="is_publication_yes" autoComplete="off" {...register("is_publication")} />
                                            <label className={`${styles['btn']} btn-outline-primary`} htmlFor="is_publication_yes">JA</label>
                                            <input type="radio" className={`${styles['btn-check']} btn-check`} value={"no"} id="is_publication_no" autoComplete="off" {...register("is_publication")} />
                                            <label className={`${styles['btn']} btn-outline-primary ${styles['pink-border']}`} htmlFor="is_publication_no">NEJ</label>
                                        </div>
                                    </div>
                                </div>
                                {watch('is_publication') == 'yes' &&
                                    <div className={`${styles['form-group']} form-group w-100 float-left`}>
                                        <div className={`input-box w-100 float-left with-icon`}>
                                            <i className="form-icon-7"></i>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    className={`${styles['btn']} ${styles['dropdown-toggle']} w-100 float-left`}>
                                                    { selectedPublications != '' ? selectedPublications : 'VÆLG EN AVIS FRA LISTEN' }
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu
                                                    className={`${styles['dropdown-menu']} w-100 float-left`}>
                                                    <div className={`${styles['option-selection']} w-100 float-left`}>
                                                        {publications?.map((publication, index) => (
                                                            <div key={index} className="btn-group btn-group-vertical w-100 float-left"
                                                                 role="group">
                                                                <input
                                                                    {...register("publications")}
                                                                    value={publication.name}
                                                                    type="checkbox"
                                                                    className={`${styles['btn-check']} btn-check`}
                                                                    id={`custom-checkbox-${index}`}
                                                                    key={publication.name}
                                                                    onChange={() => handlePublications(index)}
                                                                    checked={checkedPublications ? checkedPublications[index] : false}
                                                                />
                                                                <label
                                                                    className={`${styles['btn']} btn btn-outline-primary`}
                                                                    htmlFor={`custom-checkbox-${index}`}>
                                                                    <span className={styles['circle']}></span> { publication.name } <span
                                                                    className={styles['price']}>{ publication.price } kr.</span>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <span className="invalid-feedback-error">{errors?.publications?.message}</span>
                                        </div>
                                    </div>
                                }

                                <div className="btn-row w-100 float-left extra-pad">
                                    <button className="green-btn multiple-buttons w-100 float-right">GODKEND ANNONCEN &amp; BETALE { paymentAmount } kr. <RightArrow /></button>
                                    <button onClick={(e) => handleSteps('previous')} className={`${styles['back-btn']} float-left`}><BackArrow /> Tilbage</button>
                                </div>
                                <div className={`${styles['payment-icon']} w-100 float-right text-end`}>
                                    <img src={PaymentMobilePayLogo} alt="" className="img-fluid m-auto" />
                                    <img src={PaymentMasterCardLogo} alt="" className="img-fluid m-auto" />
                                    <img src={PaymentVisaLogo} alt="" className="img-fluid m-auto" />
                                    <img src={TrustPilotStars} alt="" className="img-fluid m-auto" />
                                </div>
                            </div>
                        </form>
                    </Col>

                    <Col lg={6} className={`order-lg-0`}>
                        <div className={`${styles['step-left']} w-100 float-left text-center`}>
                            <div className={`${styles['step-3-info']} w-100 float-left text-center horizontal-center`}>
                                <ul>
                                    <li>
                                        <div className={`${styles['title']} w-100 float-left`}>Hvorfor er prisen kun <span>99 kr.?</span></div>
                                        <p>Prisen på 99 kr. dækker oprettelsen af din online dødsannonce på sidstehilsener.dk som bliver online for evigt.</p>
                                    </li>
                                    <li>
                                        <div className={`${styles['title']} w-100 float-left`}>Hvad koster det at <span>indrykke</span> annoncen i avisen?</div>
                                        <p>Vi har samlet alle de landsdækkende og lokale aviser på én platform for at gøre det nemt for dig. Her vises de samme priser som aviserne selv opkræver for at indrykke en annonce.</p>
                                    </li>
                                    <li>
                                        <div className={`${styles['title']} w-100 float-left`}>Hvad er <span>tidsrammen</span> for indrykning af annoncer i aviser?</div>
                                        <p>Tidsrammen kan variere afhængig af den specifikke avis. Vi anbefaler, at du opretter din annonce mindst 2-3 dage før den ønskede publiceringsdato for at sikre rettidig indrykning.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ObituaryThirdStep;
