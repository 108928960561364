import * as yup from "yup";

const obituaryFirstStepSchema = yup.object({
    full_name: yup.string().required('Indtast venligst det fulde navn.').max(50, 'Maksimalt 50 tegn tilladt.'),
    birth_date: yup.date().nullable(),
    departed_date: yup.date().required('Indtast venligst det gik bort dato.'),
    city: yup.string().required('Indtast venligst det by.'),
    funeral_date: yup.date().required('Indtast venligst det dato.'),
    // funeral_time: yup.date().required('Indtast venligst det tid.'),
    address: yup.string().required('Indtast venligst det sted.'),
    type: yup.string().required('Indtast venligst det type.'),
}).required();

export default obituaryFirstStepSchema;