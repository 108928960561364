import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import Layout from "../components/Layout/Layout";
import styles from '../styles/blog.module.scss';
import CallToAction from "../components/CallToAction";
import {getAll as getBlogs, get as getBlog} from "../services/Apis/Blogs.service";
import Loading from "../components/Loading";
import {Link, useParams} from "react-router-dom";
import IBlog from "../interfaces/IBlog";
import {Helmet} from "react-helmet";

function BlogDetail() {

    const [isLoading, setIsLoading] = useState(true);
    const [blog, setBlog] = useState<IBlog>();

    const { slug } = useParams();

    useEffect(() => {
        (async () => {
            const response = await getBlog(slug);
            setBlog(response);
            setIsLoading(false);
        })();
    }, [])

  return (
      <Layout>
          <>
              {isLoading &&
                  <Loading />
              }

              {!isLoading &&
                  <>
                  <Helmet>
                      <title>{ process.env.REACT_APP_NAME + ` Blog - ` + blog?.meta_title } </title>
                      <meta name="keywords" content={ blog?.meta_keywords } />
                      <meta name="description" content={ blog?.meta_description } />
                  </Helmet>

                    <Container>
                        {blog !== undefined &&
                            <>
                                <h1>{ blog?.title }</h1>
                                <div className={`${styles['blog-data-row']} w-100 float-left`}>
                                    <div className={`${styles['blog-box']} w-100 float-left`}>
                                        <div className={`${styles['blog-info']} w-100 float-left`}>
                                            <a href="#">{ blog.author }</a> | { blog.date }
                                        </div>
                                        <h2>{ blog.title }</h2>
                                        <div dangerouslySetInnerHTML={ {__html: blog.description} } />
                                    </div>
                                </div>
                            </>
                        }
                    </Container>
                  </>
              }
              <CallToAction />
          </>
      </Layout>
  );
}

export default BlogDetail;
